.profile-view-user-me {
    width: 100%;
    float: left;
}

.profile-view-user-me-content {
    height: 90%;
    width: 90%;
    margin: 1rem auto;
}

.profile-view-user-me-info-block {
    font-size: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.profile-view-user-me-info-block > span {
    font-size: 1.6rem;
}

.profile-view-user-me-info-block-input {
    font-size: 2rem;
    border-radius: 10px;
    padding: 2px 10px;
}

.profile-view-user-me-info-block-controllers {
    display: flex;
    gap: 1rem;

    button {
        border: none;
        border-radius: 10px;
        padding: 5px 10px;
        font-size: 1.5rem;
    }

    .__save {
        background-color: #3d73b9;
        color: #fff;
    }
}

.user-profile-card-container {
    width: 96%;
    height: 96%;
    margin: auto;
}