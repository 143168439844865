.offer-product-component{
    width: 60%;
    /*border: 1px solid darkgray;*/
    /*border-radius: 20px;*/
    display: flex;
    flex-direction: column;
    background-color: white;
    min-width: 550px;
}

.offer-product-component:hover{
    /*box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);*/
}

.offer-product-component-header{
    /*border: 1px solid green;
    border-radius: 10px;*/
    /*text-align: center;*/
    /*width: 25%;*/
    /*min-width: 100px;*/
    /*margin: 5%;*/
    margin-top: 4%;
    margin-left: 4%;
}

.offer-product-component-body{
    background-color: white;
    width: 100%;
    min-width: 550px;
    display: flex;
    align-items: center;
    padding-bottom: 1%;
    font-size: small;
    margin-top: 1%;
    margin-bottom: 2%;
}

.offer-product-component-body div{
    width: 47.5%;
}

.offer-product-component-body-elem-left{
    margin-right: 5%;
    text-align: right;
}

.hr-line{
    background-color: gray;
    width: 90%;
    height: 1px;
    margin: 0%;
}

.offer-product-component-footer{
    /*margin: 5%;*/
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
}
