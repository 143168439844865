.AdminAddPropertyFormInputItem {
  width: auto;
}

#AdminProductUpadate-overlay {
  position: fixed;
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  cursor: pointer;
}

.AdminProductUpadate-form-layout {
  position: fixed;
  top: 5%;
  left: 10%;
  right: 10%;
  bottom: 20%;
  z-index: 99; 
  background-color: #fff;

  border-radius: 10px;
  transition: .3s ease;
  width: 80%;
  height: 750px;

  box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
}

.AdminProductUpadate-modal-container {
  padding: 1rem;
  /* position: relative; */
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.AdminProductUpadate-modal-close-btn {
  width: var(--header-height);
  height: var(--header-height);
  border-radius: 0 var(--window-border-radius) 0 0;
  background: transparent;
  position: relative;
  display: block;
  z-index: 200;
  text-indent: -9999px;
  cursor: pointer;
  padding: 2px;
  transition: all 0.3s ease-out;
}

.AdminProductUpadate-modal-close-btn:before,
.AdminProductUpadate-modal-close-btn:after {
  content: '';
  width: 80%;
  height: 2px;
  background: #7c7c7c;
  position: absolute;
  top: 45%;
  left: 10%;
  transform: rotate(45deg);
  transition: all 0.3s ease-out;
}

.AdminProductUpadate-modal-close-btn:after {
  transform: rotate(-45deg);
  transition: all 0.3s ease-out;
}