.main-page-body-fourth-container-plus {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    margin: 70px 0;
}

.main-page-body-fourth-container-plus-elem {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 50px;
}

.main-page-body-fourth-container-plus-elem-center {
    margin: auto;
}