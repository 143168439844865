#OrderItemAddToOrderProjectView-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  cursor: pointer;
}

.OrderItemAddToOrderProjectView-form-layout {
  position: fixed;
  top: 10vh;
  right: 15vw;
  left: 15vw;
  bottom: 10vh;
  z-index: 99;
  background-color: #fff;

  border-radius: 10px;
  transition: 0.3s ease;
  width: calc(100% - 30vw);
  height: 80vh;

  box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
}

.OrderItemAddToOrderProjectView-modal-header {
  position: fixed;
  top: 10vh;
  /* left: 30vw; */
  display: flex;
  width: calc(100% - 60vw);
  z-index: 100;
  justify-content: flex-end;
  height: 5%;
  align-items: center;
}

.OrderItemAddToOrderProjectView-modal-close-btn {
  width: var(--header-height);
  height: var(--header-height);
  border-radius: 0 var(--window-border-radius) 0 0;
  background: transparent;
  position: relative;
  display: block;
  z-index: 200;
  text-indent: -9999px;
  cursor: pointer;
  padding: 2px;
  transition: all 0.3s ease-out;
}

.OrderItemAddToOrderProjectView-modal-close-btn:before,
.OrderItemAddToOrderProjectView-modal-close-btn:after {
  content: "";
  width: 80%;
  height: 2px;
  background: #7c7c7c;
  position: absolute;
  top: 45%;
  left: 10%;
  transform: rotate(45deg);
  transition: all 0.3s ease-out;
}

.OrderItemAddToOrderProjectView-modal-close-btn:after {
  transform: rotate(-45deg);
  transition: all 0.3s ease-out;
}

.OrderItemAddToOrderProjectView-modal-container {
  padding: 1rem;
  /* position: relative; */
  width: 100%;
  height: calc(65vh - 50px);
  overflow-y: scroll;
}

.OrderItemAddToOrderProjectView-form {
  /* position: absolute; */
  width: 100%;
  height: auto;
}

.OrderItemAddToOrderProjectView-form-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.OrderItemAddToOrderProjectView-form-modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.OrderItemAddToOrderProjectView-table-bounder {
  position: absolute;
  top: 1%;
  left: 1%;
  z-index: 1;
  width: 64%;
  height: 99%;
}

.OrderItemAddToOrderProjectView-table {
  width: 100%;
  height: 95%;
}

.OrderItemAddToOrderProjectView-form-modal-content-left {
  position: absolute;
  padding: 10px;
  border-radius: 1rem;
  top: 1%;
  left: 67%;
  z-index: 1;
  width: 32%;
  height: 98%;

  box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
}

.OrderItemAddToOrderProjectView-form-modal {
  /* position: absolute; */
  width: 100%;
  height: auto;
}
