:root {
  --menu-closed-width: 5%;
  --menu-opened-width: 20%;
  --menu-btn-width: 30px;
  --apps-bar-height: 50px;
}

body > #root > div {
  height: 100vh;
}

body {
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  margin: 0;
  overflow-x: hidden;
}

*,
::after,
::before {
  box-sizing: border-box;
}

header[data-component-name="ObjectPageTopHeader"]{
  z-index: 6 !important;
}

div[data-component-name="DynamicPageHeader"]{
  z-index: 5 !important;
}

div[data-component-name="ObjectPageAnchorBar"]{
  z-index: 6 !important;
}

div[data-component-name="ObjectPageTabContainer"]{
  z-index: 5 !important;
}
