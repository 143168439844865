.main-page-footer {
    height: 100%;
    width: 100%;
}

.main-page-footer-content {
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.main-page-footer-main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 220px;
}

.main-logo-footer {
    width: 200px;
}

.main-page-footer-logo {
    margin: 20px 0 20px 0;
}

.main-page-footer-main-first {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: start;
}

.main-page-footer-main-first-text {
    font-size: 18;
    font-weight: 600;
    color: #434343D9;
    margin-bottom: 10px;
}

.main-page-footer-main-first-dashboard-buttons {
    font-size: 16px;
    font-weight: 500;
    color: #434343;
    width: auto;
    align-self: start;
    cursor: pointer;
}

.main-page-footer-main-first-messengers {
    margin: 5px;
}

.main-page-footer-messenger-logo {
    height: 100%;
    img {
        width: 80%;
        height: 80%;
        object-fit:fill;
    }
}

.main-page-footer-main-second {
    display: flex;
    flex-direction: column;
}

.main-page-footer-main-third {
    display: flex;
    flex-direction: column;
}

.main-page-footer-button {
    background-color: red;
    width: auto;
    color: white;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 400;
    align-self: start;
    padding: 0 10px;
}

.main-page-footer-year-content {
    height: 90%;
    width: 90%;
    display: flex;
    margin: auto;
}