.modal-window-close-btn {
    width: var(--header-height);
    height: var(--header-height);
    border-radius: 0 var(--window-border-radius) 0 0;
    background: transparent;
    position: relative;
    display: block;
    z-index: 200;
    text-indent: -9999px;
    cursor: pointer;
    padding: 2px;
    transition: all 0.3s ease-out;
}

.modal-window-close-btn:before,
.modal-window-close-btn:after {
    content: '';
    width: 80%;
    height: 2px;
    background: #7c7c7c;
    position: absolute;
    top: 45%;
    left: 10%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
}

.modal-window-close-btn:after {
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
}

.modal-window {
    position: fixed;
    top: 10vh;
    right: 30vw;
    left: 30vw;
    bottom: 10vh;
    z-index: 99;
    background-color: #fff;
    transform: translateY(-200%);

    border-radius: 10px;
    transition: .3s ease;
    width: calc(100% - 60vw);

    overflow-y: none;

    box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
}

.modal-window-open {
    transform: translateY(0);
}

.modal-window-container {
    height: 100%;
    width: 100%;
}

.modal-window-body {
    width: 100%;
    height: 100%;
    position: relative;
}

.modal-window-content {
    height: 96%;
    width: 96%;
    margin: auto;
}

.modal-window-header {
    position: sticky;
    top: 0;
    padding-right: 10px;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.modal-window-content {
    overflow-y: auto;
    /* overflow-x:hidden; */
    position: absolute;
    top: 32px;
    left: 0;
    z-index: 1;
    height: calc(100% - 35px);
    width: 100%;
}