.service-logo-img {
    max-height: calc(var(--apps-bar-height) - 12px);
}


.apps-items {
    display: flex;
    align-items: center;
    gap: 10px;
}

.logo {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: var(--apps-bar-height);
    transform: scale(0.8);
    transition: all .3s ease;
    cursor: pointer;
}

.logo:hover {
    transform: scale3d(0.9, 0.9, 0.9) translate(10%, 10%);
    transition: all .3s ease;
}