.main-menu-container {
    width: 100%;
}

.main-menu-content {
    width: 95%;
    margin-left: 1%;
    margin-top: 1%;
}

.main-menu-container::-webkit-scrollbar {
    width: 5px;
    /* ширина всей полосы прокрутки */
}

.main-menu-container::-webkit-scrollbar-track {
    background: transparent;
    /* цвет зоны отслеживания */
}

.main-menu-container::-webkit-scrollbar-thumb {
    background-color: #333;
    /* цвет бегунка */
    border-radius: 20px;
    /* округлось бегунка */
    border: none;
    /* отступ вокруг бегунка */
}



