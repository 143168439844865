.main-menu-block-container {
    box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
    -webkit-box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
    -moz-box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
    width: 100%;
}

.main-menu-block-content {
    width: 95%;
    margin: 0 auto;
    height: 98%;
}

.main-menu-block-title {
    font-size: 1.2rem;
    line-height: 0.5;
}

.main-menu-block-items {
    width: 94%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.main-menu-block-item {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: inherit;
    border: 2px solid transparent;
    border-radius: 10px;
    outline: solid transparent 1px;
    cursor: pointer;
    transition: .3s ease;
    padding: 5px 0.5625rem;
    box-sizing: border-box;
    justify-content: flex-start !important;
    display: block;
    text-decoration: none;
}

.main-menu-block-item:hover {
    border: 2px solid transparent;
    outline: solid #0064d9 1px;
    background-color: #ccebfc;
    transition: .3s ease;
}