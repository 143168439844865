.product_offers {
  width: 100%;
  /* height: 80% */
  height: 100%;
}

.product_offers__content {
  width: 100%;
  overflow: auto;
  height: 100%;
  word-break: break-all;
}

tr:nth-child(even) {
  background-color: #ccc;
}

table {
  width: 100%;
  line-height: 1.5em;
}

tr {
  width: 0;
  white-space: nowrap;
  vertical-align: top;
}
