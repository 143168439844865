#permission-manager-controller {
    position: fixed;
    bottom: 0;
    right: 100px;
    z-index: 1;

    padding: 5px 20px;
    border-radius: 10px 10px 0 0 ;
    border: none;
    cursor: pointer;
    background-color: #005FF9;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
}

#permission-manager-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

#permission-manager-layout {
    position: fixed;
    bottom: 10px;
    right: 100px;
    z-index: 3;
    width: 30%;
    height: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 10px;
    background-color: rgb(34, 86, 232);;
    border-radius: 10px;
}

.permission-manager-layout-close-sb-btn {
    width: var(--header-height);
    height: var(--header-height);
    border-radius: 0 var(--window-border-radius) 0 0;
    background: transparent;
    position: relative;
    display: block;
    z-index: 200;
    text-indent: -9999px;
    cursor: pointer;
    padding: 2px;
    transition: all 0.3s ease-out;
}

.permission-manager-layout-close-sb-btn:before,
.permission-manager-layout-close-sb-btn:after {
    content: '';
    width: 80%;
    height: 4px;
    background: #fff;
    position: absolute;
    top: 45%;
    left: 10%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
}

.permission-manager-layout-close-sb-btn:after {
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
}


.permission-manager-layout {
    transform: translate(0, 110%);
    transition: all 0.5s ease-out;
}

.permission-manager-layout-open {
    transform: translate(0, 0);
    transition: transform .3s ease;
}

.permission-manager-layout-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.permission-manager-layout-header {
    display: flex;
    align-items: center;
    height: 6%;
    width: 95%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 2.5%;
    z-index: 1;
    color: #fff;
    justify-content: space-between;
}

.permission-manager-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 94%;
    background-color: #fff;
    border-radius: 10px;

    overflow-y: auto;
}

.permission-manager-container {
    width: 94%;
    margin: 10px auto;
}

@media screen and (max-width: 1900px) {
    #permission-manager-layout {
        width: 40%;
    }
}

@media screen and (max-width: 1400px) {
    #permission-manager-layout {
        width: 45%;
    }
}

@media screen and (max-width: 1200px) {
    #permission-manager-layout {
        width: 50%;
    }
}

@media screen and (max-width: 1000px) {
    #permission-manager-layout {
        right: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }

    .permission-manager-layout-header {
        height: 6%;
    }

    .permission-manager-content {
        top: 6%;
        height: 94%;
    }
}