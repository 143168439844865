.SearcherProductImageCard-card {
    display: flex;
    height: 270px;
    width: 250px;
    gap: 10px;
    color: #545454;
    cursor: grab;
}

.SearcherProductImageCard-photo {
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 270px;
    width: 250px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.SearcherProductImageCard-photo-img {
    border-radius: 40px;
    z-index: 1;
    width: 250px;
    height: 270px;
    object-fit: fill;
    background-size: contain;
    padding: 20px;
}

.SearcherProductImageCard-photo-zoom-in {
    background-color: 545454;
    position: absolute;
    margin-left: 190px;
    margin-top: 190px;
    z-index: 1;
    opacity: 0.7;
}
