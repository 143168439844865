.exchange-information {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.exchange-start-time {
  display: flex;
  gap: 1rem;
  border: 2px solid #0057d2;
  border-radius: 1rem;
  padding: 0 1rem;
}

.exchange-end-time {
  display: flex;
  gap: 1rem;
  border: 2px solid #0057d2;
  border-radius: 1rem;
  padding: 0 1rem;
}

.exchange-status {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.config-fields {
  align-items: center;
  text-align: center;
  border: 2px solid #0057d2;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  /* display: grid;
  grid-template-columns: 2fr 1fr 2fr; */
}

.config-fields-config-content {
  width: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15vw,6fr));
  gap: 1rem;
}

.exchange-config {
  margin-bottom: 1rem;
}

.config-user-variant {
  width: 100%;
  padding: 1rem;
  border-radius: 1rem 1rem 0 0;
}

.config-our-field {
  width: 100%;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
}

.config-fields-name {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.errors-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border: 2px solid red;
  border-radius: 1rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.errors-field-name {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 1rem;
  border-bottom: 2px solid #0057d2;
}

.exchange-config-tool {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin-top: 15px;
}

.exchange-page{
  padding: 1rem;
}

.exhange-errors-content{
  overflow-y: auto;
}
.exchange-table .MuiTableContainer-root {
  height: fit-content;
  max-height: 630px;
}
