.order-item-add-btn {
  margin-bottom: 1rem;
}

.order-item-replace-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
}

.order-item-replace-content {
  display: flex;
  gap: 1rem;
  align-items: center;
}
