.sales-container {
    margin-top: 1rem;
    display: flex;
    grid-template-columns: 1fr 4fr;
    gap: 1rem;
    height: calc(100% - 1rem);
    width: 100%;
}

.sale-content-chapter {
    position: relative;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 .125rem 0 var(--_ui5-v1-21-2_side_navigation_shadow_color1), 0 .5rem 1rem 0 var(--_ui5-v1-21-2_side_navigation_shadow_color2);
    border-radius: .5rem .5rem 0 0;
}