.market-settings-content {
  text-align: center;
}
.market-settings-title {
  margin-bottom: 1rem;
}
.settings-fields-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.setting-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  border: 1px #ccc solid;
  padding: 1rem;
  border-radius: 1rem;
  width: 250px;
}

.setting-field img {
  width: 150px;
  height: 150px;
  object-fit: fill;
}

.icon-uploader {
  position: absolute;
  bottom: 0;
  background-color: #333;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-uploader input {
  cursor: pointer;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.icon-uploader-none {
  position: absolute;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  text-align: center;
}

.cancel-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.text-inputs {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.setting-field-icons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.setting-field-icon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.setting-field-default-product-img {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}