.searcher__results__container {
    width: 100%;
    padding: 5px;
    /* overflow-y: scroll; */
    height: 100%;
    
}

.searcher__results__container::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
.searcher__results__container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 5px;
}

/* Handle */
.searcher__results__container::-webkit-scrollbar-thumb {
    background: #7b91a8;
    border-radius: 5px;
}

.searcher__results__empty {
    width: 100%;
    height: fit-content;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
    border-radius: 5px;
    color: #7c7c7c;
}