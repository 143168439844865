.main-page-header {
    width: 100%;
    height: 100px;
}

.main-page-header-content {
    width: 90%;
    height: 90%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    margin: auto;
}

.main-logo {
    width: 250px;
    height: 50px;
}

.main-logo-a {
    width: 250px;
}

.main-page-header-left-side{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: start;
}

.main-page-header-right-side{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
}

.main-page-header-right-side-button {
    color: #434343;
    font-size: 16px;
    text-decoration: none;
}