.AdminUpdatePropertyFormInputItem {
  width: auto;
}

.icon-uploader {
  position: absolute;
  bottom: 0;
  background-color: #333;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-uploader-none {
  position: absolute;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  text-align: center;
}

.setting-field1 {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  border: 1px #ccc solid;
  padding: 1rem;
  border-radius: 1rem;
  justify-content: center;
  padding: 20px;
}

.cancel-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.catalog-product-properties-card-section-photo-card1 {
  display: flex;
  flex-direction: column;
}

.update-error {
  display: flex;
  justify-content: center;
  align-content: center;
}

.big-image-error-text {
  color: red;
  display: flex;
  align-content: center;
  justify-content: start;
}