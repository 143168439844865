.ProductSearchHeader__logo {
    position: absolute;
    top: 6px;
    padding: 4px 28px 0 30px;
    left: 1%;

    a{
        width: 100%;
        height: 30px;
    }

    img {
        width: 40px;
        height: auto;
    }
}

.ProductSearchHeader-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.ProductSearchHeader-container {
    width: 80%;
    margin: auto;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.seracher__form {
    position: relative;
    min-width: 384px;
    max-width: 584px;
    margin: 0;
    height: fit-content;
    max-height: 100px;
}

.searcher__input__area {
    flex: 1;
    display: flex;
    padding: 5px 8px 0 14px;
    color: black;
    width: 100%;
    height: fit-content;
    justify-content: flex-start;
    max-height: 95px;

    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);

    .searecher__input__area__icon__container {
        display: flex;
        align-items: center;
        padding-right: 13px;
        margin-top: -5px;
        height: 46px;

        .searecher__input__area__icon__content {
            margin: auto;

            .searecher__input__area__icon__span {
                height: 20px;
                line-height: 20px;
                width: 20px;
            }
        }
    }

    .searecher__input__area__input__container {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        margin-top: 6px;
        height: fit-content;
        max-height: 89px;

        input {
            font-family: arial, sans-serif;
            line-height: 22px;
            font-size: 1rem;
            margin-bottom: 8px;
            overflow-x: hidden;
            max-height: 80px;

            resize: none;
            background-color: transparent;
            border: none;
            margin: 0;
            padding: 0;
            color: #000;
            word-wrap: break-word;
            outline: none;
            display: flex;
            width: 100%;
            -webkit-tap-highlight-color: transparent;
        }
    }

    .searcher__input__area__controllers__container {
        display: flex;
        flex: 0 0 auto;
        margin-top: -5px;
        align-items: stretch;
        flex-direction: row;
        height: 44px;

        .searcher__input__area__controllers__icon__container {
            flex: 1 0 auto;
            display: flex;
            cursor: pointer;
            align-items: center;
            border: 0;
            background: transparent;
            outline: none;
            padding: 0 8px;
            width: 44px;
            line-height: 44px;

            .searcher__input__area__controllers__icon__span {
                height: 44px;
                width: 44px;
                vertical-align: middle;

                img {
                    overflow-clip-margin: content-box;
                    overflow: clip;
                }

                svg {
                    display: block;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}

.seracher__form__input {
    width: 100%;
    height: 42px;
    padding-left: 10px;
    border: 3px solid #0070f2;
    outline: none;
    padding: 0 5px;
}

.seracher__form__input:focus {
    outline: none;
}

.seracher__form__btn {
    position: absolute;
    top: 0;
    right: 0px;
    height: 42px;
    border: none;
    background: #0070f2;
    color: #fff;
    font-size: 18px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.suggestions__dropdown__block {
    position: absolute;
    top: 101%;
    left: 0;
    width: 100%;
    z-index: 999;
    height: fit-content;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    transition: max-height .2s linear;
}

.suggestion__item {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.suggestion__item:hover {
    background-color: #eee;
}

.ProductSearchHeader-controllers-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: 10px;
}