.exchange-create-tools {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.exchange-create-page-container {
  width: 100%;
  height: 100%;
  padding: 1rem;
}
