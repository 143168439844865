.catalog-view-content {
  width: 100%;
}

.catalog-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.catalog {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;

  gap: 1rem;
}
