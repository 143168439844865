.company-form {
    border: 1px solid gray;
    border-radius: 10px;
    width: 100%;
    padding: 5%;
}

.company-item-panel {
    width: 100%;
}

.my-companies{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.company-contacts{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.company-contact{
    border-top: 1px solid gray;
    width: 100%;
    padding: 5%;
}

.company-form-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5%;
}

.company-create-button{
    align-self: flex-end;
}