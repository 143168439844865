.auth-button {
    color: #000;
}

.auth-button:hover {
    color: #0a6ed1;
}

* {
    --_ui5-v1-18-0_input_focus_outline_color: transparent;
}