.catalog-product-properties-card{
    width: 100%;
    display: flex;
    justify-content: column;
}

.catalog-product-properties-card-row{
    /*background-color: white;*/
    width: 100%;
    min-width: 750px;
    flex-direction: row;
    display: flex;
    /*align-items:;*/
    padding-bottom: 1%;
    font-size: small;   
}

.catalog-product-properties-card-row div{
    /*width: 47.5%;*/
    /*text-align: right;*/
}

.catalog-product-properties-div-left{
    width: 25%;
    margin-left: 2%;
    margin-right: 2%;
    text-align: right;
}

.catalog-product-properties-div-right{
    width: 60%;
    margin-right: 2%;
    text-align: left;
}


.mini-wall{
    height: 100%;
    width: 2px;
    background-color: black;
}



/* product properties card removed from CatalogProductPropertiesCardSection.css */
.catalog-product-properties-card-section-div{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;
    width: 100%;
}

.catalog-product-properties-card-section{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;
    width: 100%;
}