.element {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  text-align: center;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.exchange-name {
  cursor: pointer;
}

.exchange-name:hover {
    color: #0057d2;
}

.upload-file-btn-container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-file-btn  {
    width: 10rem;
    align-self: center;
}
