.product__searcher__result__card__container {
    width: 100%;
    min-height: 20rem;
    height: fit-content;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
    margin: 10px 0;

    /* position: relative; */
}

.product__searcher__result__card__header {
    background-color: #fefefe;
    height: 30%;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    gap: 10px;
}

.product__searcher__result__card__header__content {
    width: 100%;
    height: 95%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.product__searcher__result__card__header__content__left__side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    align-items: start;
    padding: 10px;
}

.product__searcher__result__card__header__content__center__side {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.product__searcher__result__card__header__icon__content {
    height: 60px;
    margin: auto 0;
    width: 60px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        border-radius: 5px;
        max-width: 100%;
        height: auto;
    }
}

.product__searcher__result__card__header__title {
    font-size: 2rem;
    font-weight: bold;
    color: rgb(34, 34, 34);
}

.product__searcher__result__card__header__body__elem {
    font-size: 1.2rem;
    flex-wrap: wrap;

    span {
        font-weight: bold;
        padding: 2px 4px;
        border-radius: 5px;
    }
}

.product__searcher__result__card__body {
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    padding: 10px 20px;
}

.product__searcher__result__card__body__deliveries {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 0 20px;
}

.product__searcher__result__card__body__deliveries__list__container {
    width: 100%;
    height: 100%;
    background-color: #D9D9D930;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.product__searcher__result__card__body__deliveries__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-height: 160px;
    padding: 20px;
    gap: 20px;
    overflow-y: scroll;
}

.product__searcher__result__card__body__deliveries__list__big {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-height: 400px;
    padding: 20px;
    gap: 20px;
    overflow-y: scroll;
}

.product__searcher__result__card__body__deliveries__list__elem {
    display: grid;
    width: 100%;
    grid-template-columns: 5% 25% 35% 35%;

    span {
        display: flex;
        align-items: center;
    }
}

.product__searcher__result__card__body__deliveries__list__elem__first {
    display: flex;
    gap: 5px;
}

.product__searcher__result__card__body__deliveries__elem {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
    color: #4E4E4E;
}

.product__searcher__result__card__body__deliveries__icon {
    height: 25px;
}

.product__searcher__result__card__body__deliveries__list__expander {
    width: 10px;
    border-color: #0070F2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product__searcher__result__card__body__address__select {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 600;
}

.product__searcher__result__card__body__address__select_btn {
    border-color: #0070F2;
}

.product__card__property__block__item {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1rem;
    margin: 2px;
}

.product__card__property__block__item__name {
    font-weight: 800;
    color: #5c5c5c;
}

.product__card__property__block__item__value {
    height: fit-content;
    width: fit-content;
    padding: 2px 5px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    background-color: #eee;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #5c5c5c;
}

.product__searcher__result__card__footer {
    /* position: absolute; */
    top: 101%;
    left: 0;
    z-index: 1;
    background-color: #fff;

    transform: scaleY(0);
    max-height: 0;
    padding: 10px;
    width: 100%;
    border-radius: 5px;

    transition: transform .4s ease;
    transition: max-height .3s ease-out;
    transform-origin: top center;
}

.product__searcher__result__card__footer__expanded {
    background-color: #D9D9D930;
    border-radius: 15px;
    height: auto;
    max-height: 300px;
    overflow-y: scroll;
    display: block;
    transform: scaleY(1);
    transition: transform .4s ease;
    transition: max-height .3s ease-out;
    transform-origin: top center;
}

.product__searcher__result__card__footer__content {
    position: initial;
}

.product__searcher__result__card__header__tools__button_buy_disable,
.product__searcher__result__card__header__tools__button_buy_enable {
    display: flex;
    flex-direction: column;
    width: inherit;
    height: inherit;
    margin: 10px;
    padding: 10px;

    /* Для анимации */
    position: relative;
    overflow: hidden;
}

.product__searcher__result__card__header__tools__button_buy_enable:after {
    content: "";
    display: block;
    width: 20px;
    height: 200px;
    margin-left: 50px;
    background: #fff;
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 0.5) 100%);
    left: -40px;
    top: -100px;
    z-index: 1;
    transform: rotate(45deg);
    position: absolute;
    animation: movingFlare 5s ease-in-out 0.05s infinite;
}

@keyframes movingFlare {
    0% {
        left: -30px;
        margin-left: 0px;
    }

    15% {
        left: 110%;
        margin-left: 80px;
    }

    100% {
        left: 110%;
        margin-left: 80px;
    }
}

.product__searcher__result__card__header__tools__button_second_step {
    display: flex;
    flex-direction: column;
    width: inherit;
    margin: 10px;
    padding: 10px;
}

.product__searcher__result__card__header__tools__orders {
    display: flex;
    flex-direction: row;
    width: inherit;
    flex-wrap: wrap;
}

.product__searcher__result__card__header__product__properties {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.product__searcher__result__card__header__product__prop__value {
    font-size: 24px;
    color: #4E4E4E;
    cursor: help;
}

.product__searcher__result__card__header__product__properties__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.product__searcher__result__card__header__product__properties__mid {
    display: flex;
    align-items: center;
    gap: 60px;
}

.product__searcher__result__card__header__product__properties__bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.product__card__delivery__block__item {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1rem;
    margin: 2px;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1300px) {
    .product__card__delivery__block__item {
        display: flex;
        grid-template-columns: 1fr 4fr;
        gap: 1rem;
        margin: 2px;
        flex-direction: column;
    }

    .product__searcher__result__card__header__body {
        display: block;
    }
}

@media screen and (max-width: 650px) {
    .product__card__property__block__item {
        display: flex;
        grid-template-columns: 1fr 4fr;
        gap: 1rem;
        margin: 2px;
        flex-direction: column;
    }
}

.product__card__delivery__block__item__name {
    font-weight: 800;
    color: #5c5c5c;
    justify-content: center;
}

.product__card__delivery__block__item__value {
    height: fit-content;
    width: fit-content;
    padding: 2px 5px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    background-color: #eee;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #5c5c5c;
}

.product__card__delivery__count__block__item__value {
    padding: 2px 5px;
}

.product__card__delivery__time__block__item__value {
    padding: 2px 5px;
}

.geo_object__empty__container {
    width: 100%;
    height: 100%;

    h4 {
        background-color: rgb(225, 236, 183);
        padding: 3px 5px;
        text-align: center;
        border-radius: 5px;
    }
}

#ProductSearcherCard-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    cursor: pointer;
}

.SearcherProductImageCard-card-full {
    height: 80%;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 100;
    cursor: pointer;
}

.SearcherProductImageCard-photo-img-full {
    position: fixed;
    object-fit: contain;
    height: 100%;
    width: 100%;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}