#user-company-controller-modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.user-company-controller-modal {
    position: fixed;
    top: 10vh;
    right: 30vw;
    left: 30vw;
    bottom: 10vh;
    z-index: 99;
    background-color: #fff;
    transform: translateY(-200%);

    border-radius: 10px;
    transition: .3s ease;
    width: calc(100% - 60vw);

    overflow-y: auto;

    box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
}

.user-company-controller-modal-open {
    transform: translateY(0);
}

.user-company-controller-modal-header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 5%;
    align-items: center;
}

.user-company-controller-modal-close-btn {
    width: var(--header-height);
    height: var(--header-height);
    border-radius: 0 var(--window-border-radius) 0 0;
    background: transparent;
    position: relative;
    display: block;
    z-index: 200;
    text-indent: -9999px;
    cursor: pointer;
    padding: 2px;
    transition: all 0.3s ease-out;
}

.user-company-controller-modal-close-btn:before,
.user-company-controller-modal-close-btn:after {
    content: '';
    width: 80%;
    height: 2px;
    background: #7c7c7c;
    position: absolute;
    top: 45%;
    left: 10%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
}

.user-company-controller-modal-close-btn:after {
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
}

.user-company-controller-modal-container {
    height: 95%;
    width: 95%;
    margin: auto;
}

.user-company-modal-content {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.user-company-control-btn {
    background-color: #0070f2;
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 10px;
    border: none;
    font-weight: 500;
    cursor: pointer;
    flex-shrink: 0;
}

.user-company-control-btn-empty {
    background-color: #7c7c7c;
}

.user-company-item-card-container {
    width: 90%;
    margin: 10px auto;
}

.user-company-item-card-container > button {
    
}

@media screen and (max-width: 1600px) {
    .user-company-controller-modal {
        right: 20vw;
        left: 20vw;
        width: calc(100% - 40vw);
    }
}

@media screen and (max-width: 1200px) {
    .user-company-controller-modal {
        right: 10vw;
        left: 10vw;
        width: calc(100% - 20vw);
    }

    .user-company-controller-modal-header-name {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 980px) {
    .user-company-controller-modal {
        right: 10vw;
        left: 10vw;
        width: calc(100% - 20vw);
    }

    .user-company-controller-modal-current-badge-top {
        display: none;
    }

    .user-company-controller-modal-current-badge-bottom {
        display: block;
    }
}

@media screen and (max-width: 750px) {
    .user-company-controller-modal {
        right: 1vw;
        left: 1vw;
        width: calc(100% - 2vw);
    }
}

@media screen and (max-width: 580px) {
    .user-company-controller-modal {
        right: 1vw;
        left: 1vw;
        width: calc(100% - 2vw);
    }

    .user-company-controller-modal-header-name {
        font-size: 12px !important;
    }

    .user-company-controller-modal-current-badge-top {

    }

    .user-company-controller-modal-current-badge-bottom {

    }
}