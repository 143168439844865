.catalog-my-product-card{
    display: flex;
    width: 100%;
    justify-content: space-around;
    background-color: white;
    padding: 20px;
    border-radius: 10px
}

.catalog-my-product-card-editable-step-input{
    width: 200px;
    border: 1px solid #bdbdbd;
    border-bottom: 1px solid #666666;
    color: #333333 !important;
}

.catalog-my-product-card-page-subsection {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;
}