.profile-view-container {
    display: flex;
    flex-direction: row;
    gap: 100px;
}

.profile-view-user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
    width: 500px;
}

.profile-view-user-info-img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    object-fit: cover;
    user-select: none;
}

.profile-view-user-info-text {
    margin: 10px 0;
}

.profile-view-user-info-name {
    font-size: 2.5rem;
}

.profile-view-user-info-email {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.7);
}

/* ARM Item */
.arm-item-content {
    width: 100%;
    height: auto;
    border: 2px solid rgba(162, 162, 162, 1);
    background-color: rgba(254, 254, 254, 1);
    border-radius: 1rem;
    transition: all .3s ease;
    cursor: pointer;
}

.arm-item-content:hover{
    background-color: rgba(230, 230, 230, 1);
    transition: all .3s ease;
}

.arm-item-container {
    height: 90%;
    width: 90%;
    margin: 5px auto;
}

.arm-item-title {
    color: rgba(0, 0, 0, 1);
    font-size: 1.6rem;
    margin-bottom: 1rem;
    text-align: center;
    user-select: none;
}

.arm-item-text {
    color: rgba(94, 94, 94, 1);
    font-size: 1.4rem;
    text-wrap: wrap;
    user-select: none;
}
/*  */

/* ExtendedMenu Item */
.extended-menu-item-content {
    width: fit-content;
    background-color: transparent;
    transition: all .3s ease;
    border-radius: 10px;
    cursor: pointer;
}

.extended-menu-item-content:hover {
    background-color: rgba(230, 230, 230, 1);
    transition: all .3s ease;
}

.extended-menu-item-container {
    margin: 10px 20px;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.extended-menu-item-container > img {
    width: auto;
    height: auto;
}

.extended-menu-item-title {
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    line-height: 1.5;
}

.extended-menu-item-text {
    font-size: 0.9rem;
    color: rgba(94, 94, 94, 1);
    text-align: left;
}
/*  */

.profile-view-arm {
    margin: 5rem 0;
}


.profile-view-arm-title {
    font-size: 3rem;
    /* font-weight: 600; */
    text-align: center;
    
}

.profile-view-arm-content {
    margin: 3rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem; 
}

.profile-view-extended-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;

    margin-bottom: 3rem;
}