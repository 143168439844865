.SalesMainChapterCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    height: 100%;
    box-shadow: 0 0 .125rem 0 var(--_ui5-v1-21-2_side_navigation_shadow_color1), 0 .5rem 1rem 0 var(--_ui5-v1-21-2_side_navigation_shadow_color2);
    transition: transform .3s ease;

    &:hover {
        transform: scale(1.01);
    }

    .SalesMainChapterCard__icon {
        width: clamp(3rem, 4.5rem, 6rem);
    }

    .SalesMainChapterCard__title {
        padding: 5px 10px;
        font-size: clamp(1rem, 1.2rem, 1.5rem);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: .5rem .5rem 0 0;
        min-height: 50px;
        max-height: 80px;
        height: 10%;
    }

    .SalesMainChapterCard__text {
        padding: 5px 10px;
        font-size: clamp(0.7rem, 1rem, 1.2rem);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 0 0.5rem.5rem;
        min-height: 50px;
        max-height: 80px;
        height: 90%;
    }
}

.SalesMainChapterCard {}

.sales-main-container {
    display: grid;
    padding: 1rem;
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
    height: 100%;
    width: 100%;
}

.sales-main-subcontainer {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.sales-main-subcontainer > div:nth-child(4) {
    grid-column: span 3;
}

.sales-main-subcontainer-second {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    height: 100%;
    width: 100%;
    cursor: pointer;
}
