.comment-view-container {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    position: relative;
    background-color: white;
    border-radius: 10px;
    margin-top: 1rem;
}

.comment-view-header {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    font-family: 72override;
    text-overflow: ellipsis;
    color: #1d2d3e;
    gap: 10px;
}

.comment-view-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: 800px;
    overflow-y: auto;
    justify-content: flex-start;
    padding: 10px;
}

.comment-view-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: white;
    padding: 5px 20px;
    align-items: center;
    border-radius: 0 0 10px 10px;
    gap: 5px;
}

.comment-view-input {
    width: 100%;
    height: auto;
    max-height: 60px;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    line-height: 1.5;
    box-sizing: border-box;
    overflow: scroll;
    font-size: 18px;
}

.image-upload {
    display: none;
}

.reply-comment {
    display: flex;
    height: auto;
    width: 100%;
    padding-left: 60px;
}

.comment-view-footer-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    gap: 5px;
}

.image-preview, .files-preview  {
    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 60px;
    gap: 5px;
}

.files-preview {
    flex-direction: column;
}

.file-preview-current, .image-preview-current {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
}

.image-preview-current {
    height: 150px;
    width: auto;
}

.file-preview-current-file-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.comment-view-content-unread-comments-text {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #7bcfff;
    font-weight: bold;
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
    gap: 10px;
}
