.catalog-main-page-catalog-card1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 10px;
    text-decoration: none;
    color: #545454;
    text-align: center;
    font-size: 20px;
}

.catalog-main-page-catalog-card-photo {
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.catalog-main-page-catalog-card-photo-img {
    width: 240px;
    height: 220px;
    padding: 10px;
    border-radius: 40px;
    object-fit: fill;
}

.catalog-main-page-catalog-card-photo-img:hover {
    padding: 0;
}

.slick-slide {
    height: 300px;
}

.catalog-main-page-catalog-card-name {
    display: flex;
    flex-direction: column;
    color: black;
}