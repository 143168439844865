.main-header-container {
    width: 100vw;
    height: 5rem;
    padding: 1vh 0;
    top: 0;
    z-index: 1;
    background-color: #fff;
}

.main-haeder-content {
    width: 80vw;
    margin: 0 auto;
    height: 100%;

    display: flex;
    justify-content: space-between;
}

.main-logo {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

.main-logo-a {
    width: 200px;
}

.main-haeder-content-block {
    max-width: 50%;
    min-width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
}

.left-side {
}

.right-side {
    justify-content: flex-end;
    gap: 1rem;
}

.main-header-controller {
    background-color: transparent;
    height: 100%;
    border: none;
    color: inherit;
}
