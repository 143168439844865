.ProductDetailView-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.ObjectPage-anchorBar-0-2-36 {
    z-index: 11;
}

.ObjectPage-tabContainer-0-2-37 {
    z-index: 10;
}