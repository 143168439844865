#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    cursor: pointer;
}

.main-side-bar-layout {
    position: fixed;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    z-index: 99;
    transform: translateX(110%);

    height: calc(100% - 2rem);
    width: 30vw;
    transition: .3s ease;

    box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
}

.expanded {
    width: 30vw;
    transform: translateX(0%);
    transition: .3s ease;
}

.sidebar-full-screen {
    width: calc(100vw - 2rem) !important;
    transition: .3s ease;
}

.main-side-bar {
    border-radius: 1rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.side-bar-container {
    width: 95%;
    height: 98%;
}

.main-side-bar-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    height: 2rem;
}

.main-side-bar-header-controller {
    padding: 2px;
    width: var(--header-height);
    height: var(--header-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}

.close-sb-btn {
    width: var(--header-height);
    height: var(--header-height);
    border-radius: 0 var(--window-border-radius) 0 0;
    background: transparent;
    position: relative;
    display: block;
    z-index: 200;
    text-indent: -9999px;
    cursor: pointer;
    padding: 2px;
    transition: all 0.3s ease-out;
}

.close-sb-btn:before,
.close-sb-btn:after {
    content: '';
    width: 80%;
    height: 2px;
    background: #7c7c7c;
    position: absolute;
    top: 45%;
    left: 10%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
}

.close-sb-btn:after {
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
}

.side-bar-content {
    height: calc(100% - 2rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.side-bar-content::-webkit-scrollbar {
    width: 5px;
    /* ширина всей полосы прокрутки */
}

.side-bar-content::-webkit-scrollbar-track {
    background: transparent;
    /* цвет зоны отслеживания */
}

.side-bar-content::-webkit-scrollbar-thumb {
    background-color: #333;
    /* цвет бегунка */
    border-radius: 20px;
    /* округлось бегунка */
    border: none;
    /* отступ вокруг бегунка */
}

@media screen and (max-width: 1600px) {
    .main-side-bar-layout {
        width: 40vw;
    }
}

@media screen and (max-width: 1200px) {
    .main-side-bar-layout {
        width: 50vw;
    }
}

@media screen and (max-width: 980px) {
    .main-side-bar-layout {
        width: 60vw;
    }
}

@media screen and (max-width: 750px) {
    .main-side-bar-layout {
        width: calc(100% - 2rem);
    }
}