.window-menu-position-back{
    position: absolute;
    background-color: gray;
    z-index: 80;
    opacity: 0.7;
    border-radius: 7px 0px 0px 7px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}

.component-rectangle-back{
    width: 50%;
    height: 100vh;
}

.rectangle-left-back{
    top: 0px;
    left: 0px;
}

.rectangle-right-back{
    top: 0px;
    left: 50%;
}

.component-square-back{
    width: 50%;
    height: 50vh;
}

.square-top-left-back{
    top: 0px;
    left: 0px;
}

.square-top-right-back{
    top: 0px;
    left: 50%;
}

.square-bottom-left-back{
    top: 50vh;
    left: 0px;
}

.square-bottom-right-back{
    top: 50vh;
    left: 50%;
}