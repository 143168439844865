.ProductsStocksView-container {
    width: 100%;
    gap: 1rem;
    height: 100%;
    /* position: relative; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.catalog-side {
    width: auto;
    /* position: absolute; */
    /* top: 0;
    left: 0;
    z-index: 1; */

    /* width: 33%; */
    width: 100%;
    height: 100%;
}

.right-side-block {
    background-color: #fff;
    width: auto;
    /* position: absolute; */
    /* top: 0;
    right: 0;
    z-index: 1; */

    /* width: 65%; */
    height: 100%;
}

.ProductsStocksView-nodata-content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}