:root {
}

.orders-view-side-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
  height: 100%;
}

.orders-view-side-content {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 98%;
  overflow-y: hidden;
}

.orders-view-side-paginator {
  position: absolute;
  z-index: 10;
  width: 100%;
}

.order-view-side-project-element {
  text-align: center;
  border: 2px solid #0057d2;
  border-radius: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: bold;
}

.order-view-side-project-element:hover {
  color: white;
  background-color: #0057d2;
}

.CatalogSideView-table {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.order-side-view-table {
  height: 100%;
  width: 100%;
  th div {
    white-space: nowrap;
    flex-wrap: nowrap;
  }

  td, span {
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  td:nth-child(n+3) button {
    width: inherit;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  td[data-index="4"] button , td[data-index="5"] button {
    width: inherit;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

