.catalog-main-page-catalog-card {
    display: grid;
    grid-template-rows: 3fr;
    min-height: 300px;
    height: 100%;
    gap: 10px;
    margin-right: 10px;
    text-decoration: none;
    color: #545454;
    text-align: center;
    font-size: 20px;
    cursor: grab;
}

.admin-product-card-photo {
    border-radius: 40px;
    display: block;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.admin-product-card-photo-img {
    margin: auto;
    position: absolute;
    top: 0;
    left: calc((100% - 300px) / 2);
    z-index: 1;
    width: 300px;
    height: 100%;
    object-fit: fill;
    background-size: contain;
    padding: 20px;
}

.admin-product-card-photo-img:hover {
    padding: 10px;
}

.catalog-main-page-catalog-card-photo-delete {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
}

.catalog-main-page-catalog-card-photo-eye {
    position: absolute;
    right: 60px;
    top: 20px;
    z-index: 1;
}

.catalog-main-page-catalog-card-photo-zoom-in {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    opacity: 0.5;
}