.user-profile-action-sheet {
    float: right;
    position: relative;
}

.user-profile-action-sheet-control {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
    user-select: none;
    cursor: pointer;
}

.user-profile-action-sheet-popup {
    position: absolute;
    width: 300px;
    height: auto;

    background-color: rgba(217, 217, 217, 0.75);

    top: 60px;
    right: 0;
    z-index: 10;
    border-radius: 1rem;
}

.user-profile-action-sheet-popup-content {
    width: 100%;
    height: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.user-profile-action-sheet-popup-container {
    width: 90%;
    height: 90%;

    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-profile-action-sheet-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    cursor: pointer;
}

.user-profile-action-sheet-popup-img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    object-fit: cover;
    user-select: none;

    margin-top: 1rem;
}

.user-profile-action-sheet-popup-email {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, .7);
}

.user-profile-action-sheet-popup-logout-btn {
    background-color: rgba(217, 217, 217, 1);
    color: black;
    border: none;
    border-radius: 1rem;
    width: 100%;
    font-size: 1.5rem;
    margin: 10px 0;
    /* padding: 10px 0; */
}

.user-profile-action-sheet-popup-license {
    text-decoration: none;
    text-transform: none;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    margin: 10px auto;
}

.link-to-profile {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: inherit;
    border: 2px solid gray;
    border-radius: 10px;
    outline: solid transparent 1px;
    cursor: pointer;
    transition: .3s ease;
    padding: 5px 0.5625rem;
    box-sizing: border-box;
    justify-content: flex-start !important;
    display: block;
    text-decoration: none;
    text-align: center;
}

.link-to-profile:hover {
    border: 2px solid transparent;
    outline: solid #0064d9 1px;
    background-color: #ccebfc;
    transition: .3s ease;
}