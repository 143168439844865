.plus-elem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.plus-elem-text-body {
    text-align: center;
    color: #434343;
    font-size: 22px;
}

.plus-elem-text-header {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: black;
    padding: 0 60px;
}

.plus-elem-text-body-2 {
    text-align: center;
    color: #434343;
    font-size: 16px;
}

.plus-elem-text-header-2 {
    text-align: center;
    font-size: 25px;
    color: black;
    font-weight: 600;
}

.plus-elem-logo {
    display: flex;
    margin:  0 auto;
    align-items: center;
    justify-content: center;
    border-radius: 90px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    width: 120px;
    height: 120px;
}