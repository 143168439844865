::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 2px grey; */
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7b91a8;
    border-radius: 5px;
  }