.side-bar-profile {
    width: 95%;
    height: fit-content;
    height: 10%;
    background-color: #fff;
    transition: .3s ease;
    border-radius: 1rem;
    box-shadow: none;
    margin-top: 1rem;
    margin-bottom: 2rem;
    box-sizing: border-box;
    box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
    -webkit-box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
    -moz-box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
}

.profile-full-height {
    height: calc(100% - 2rem) !important;
    transition: .3s ease;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.side-bar-profile-content {
    width: 90%;
    height: 100%;
    margin: 0 auto;
}

.mini-version {
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}

.side-bar-profile-mini {
    width: 95%;
    height: 95%;

    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
}

.side-bar-profile-mini {

    .user-info {
        h1 {
            font-size: 1rem;
        }

        h4 {
            font-size: 0.7rem;
        }
    }
}

.side-bar-profile-max {
    display: flex;
    flex-direction: column;
    align-items: center;
    

    .user-info {
        text-align: center;
        h1 {
            font-size: 1.2rem;
        }

        h4 {
            font-size: 0.9rem;
        }
    }
}

.side-bar-profile-max-img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    object-fit: cover;
    margin-top: 3rem;
    user-select: none;
}

.max-version {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.max-version > div {
    box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
    -webkit-box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
    -moz-box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
    width: 100%;
    border-radius: 0.5rem;
}

.side-bar-profile-mini>img {
    border-radius: 50%;
    object-fit: cover;

}



.user-info>h1,
h4 {
    line-height: 1;
}

.user-info>h4 {
    color: #757474;
}

.max-version>.user-info {
    text-align: center;
}

.user-menu {
    margin-top: 2rem;
    width: 100% !important;
}

.user-menu .ui5-sn-root {
    width: 100% !important;
}

.user-menu-content {
    width: 90%;
    height: 95%;
}

.user-menu-btn {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: left;
    font-weight: 500;
    border-radius: 0.5rem;
    border: 2px solid transparent;
    outline: solid transparent 1px;
    background-color: #dee2e5;
    cursor: pointer;
    transition: .3s ease;
}

.user-menu-btn:hover {
    border: 2px solid transparent;
    outline: solid #0064d9 1px;
    background-color: #ccebfc;
    transition: .3s ease;
}