.my_products {
    text-align: center;
}

.my_products__content {
    display: flex;
    justify-content: space-evenly;
}

td {

}

th {
    font-size: 15px !important;
    font-weight: normal !important;
    text-align: center !important;
    background-color: #fff !important;
}

.css-1bw3b93-MuiToolbar-root {
    background-color: #fff !important;
}


tr {
    background-color: #fff !important;
}

.calculateNeedfulCountBtn {
    background-color: #fff !important;
    color: #000;
    border-radius: 0;
}

.calculateNeedfulCountBtn {
    background-color: #defac1;
    color: #000;
}
