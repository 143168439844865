.profile-menu-item-content {
    background-color: #fff;
    border-radius: 5px;
    /* width: fit-content; */
    cursor: pointer;
}

.profile-menu-item-container {
    display: flex;
    align-items: center;
    margin: 10px 20px;
    font-size: 1.2rem;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    
}

.profile-menu-item-content:hover {
    background-color: rgba(217, 217, 217, .7);
}

.profile-menu-item-content-active {
    background-color: #D9D9D9;
}

.profile-menu-item-container img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.profile-view-menu {
    display: block;
}

