:root {
    --header-height: 24px;
    --window-border-radius: 0px;
}

.window-container {
    overflow: hidden;
    margin: 0;
    z-index: 0;
    background-color: #ffffff;
    color: #575B65;
    border: 1px solid #ccc;
    border-radius: var(--window-border-radius);
}

.window-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.window-header {
    position: sticky;
    top: 0;
    height: var(--header-height);
    background-color: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    user-select: none;
    cursor: move;
}

.window-title {
    padding: 6px 4px;
    line-height: 1.2;
}


.active {
    z-index: 1;
    font-size: large;
}

.active > .window-content > .window-header {
    background-color: #de0000;
}

.window-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.window-body {
    flex: 1 1 0;
    overflow: auto;
    height: calc(100% - var(--header-height));
}

.window-footer{
    height: 2px
}

.window-body::-webkit-scrollbar {
    width: 5px;
    /* ширина всей полосы прокрутки */
}

.window-body::-webkit-scrollbar-track {
    background: transparent;
    /* цвет зоны отслеживания */
}

.window-body::-webkit-scrollbar-thumb {
    background-color: #333;
    /* цвет бегунка */
    border-radius: 20px;
    /* округлось бегунка */
    border: none;
    /* отступ вокруг бегунка */
}

.cl-btn-4 {
    width: var(--header-height);
    height: var(--header-height);
    border-radius: 0 var(--window-border-radius) 0 0;
    background: transparent;
    position: relative;
    display: block;
    z-index: 200;
    text-indent: -9999px;
    cursor: pointer;
    padding: 2px;
    transition: all 0.3s ease-out;
}

.cl-btn-4:before,
.cl-btn-4:after {
    content: '';
    width: 80%;
    height: 2px;
    background: #7c7c7c;
    position: absolute;
    top: 45%;
    left: 10%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
}

.cl-btn-4:after {
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
}

.cl-btn-4:hover:before,
.cl-btn-4:hover:after {
    transform: rotate(180deg);
    background: #FFF;
}

.cl-btn-4:hover {
    background: #D21531;
    transition: all 0.3s ease-out;
}