.catalog_filter {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.vgh_input {
  width: 50%;
}

.vgh {
  display: flex;
  gap: 1rem;
}
