#MyOfferCreateForm-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    cursor: pointer;
}

.MyOfferCreateForm-form-layout {
    position: fixed;
    top: 10vh;
    right: 30vw;
    left: 30vw;
    bottom: 10vh;
    z-index: 99;
    background-color: #fff;

    border-radius: 10px;
    transition: .3s ease;
    width: calc(100% - 60vw);
    height: 80vh;

    box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.5);
}

.MyOfferCreateForm-modal-header {
    position: fixed;
    top: 10vh;
    /* left: 30vw; */
    display: flex;
    width: calc(100% - 60vw);
    z-index: 100;
    justify-content: flex-end;
    height: 5%;
    align-items: center;
}

.MyOfferCreateForm-modal-close-btn {
    width: var(--header-height);
    height: var(--header-height);
    border-radius: 0 var(--window-border-radius) 0 0;
    background: transparent;
    position: relative;
    display: block;
    z-index: 200;
    text-indent: -9999px;
    cursor: pointer;
    padding: 2px;
    transition: all 0.3s ease-out;
}

.MyOfferCreateForm-modal-close-btn:before,
.MyOfferCreateForm-modal-close-btn:after {
    content: '';
    width: 80%;
    height: 2px;
    background: #7c7c7c;
    position: absolute;
    top: 45%;
    left: 10%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
}

.MyOfferCreateForm-modal-close-btn:after {
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
}

.MyOfferCreateForm-modal-container {
    padding: 1rem;
    /* position: relative; */
    width: 100%;
    height: calc(65vh - 50px);
    overflow-y: scroll;
}

.MyOfferCreateForm-form {
    /* position: absolute; */
    width: 100%;
    height: auto;
    
}

.MyOfferCreateForm-form-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
