.main-page-body {
    display: flex;
    flex-direction: column;
}

.main-page-body-container-main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.main-page-body-container-content {
    width: 90%;
    height: 1080px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-page-body-first-container {
    background-image: url('../../../../public/static/main_page_office.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-page-body-first-elem-text-h1 {
    font-size: 60px;
    font-weight: 700;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 20px;
}

.main-page-body-first-elem-text-h2 {
    font-size: 30px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.main-page-body-first-container-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    background-color: white;
    height: 70px;
    width: 55%;
}

.main-page-body-first-elem-code-input {
    height: 80%;
    width: 40%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    outline: none;
    box-shadow: none;
    background: transparent;
    font-size: 24px;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
    --_ui5-v1-24-0_input_focus_outline_color: transparent !important;
}

:root {
    --sapField_Active_BorderColor: transparent !important;
}

:root {
    --sapField_Active_BorderColor: transparent !important;
}

/* .main-page-body-first-elem-code-input::placeholder {
    color: red; 
    font-size: 24px;
    font-weight: 600;
    margin-left: 20px;
} */



/* 
.main-page-body-first-elem-code-input::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
.main-page-body-first-elem-code-input::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
.main-page-body-first-elem-code-input:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
.main-page-body-first-elem-code-input:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
.main-page-body-first-elem-code-input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
.main-page-body-first-elem-code-input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
.main-page-body-first-elem-code-input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
.main-page-body-first-elem-code-input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;} */

.main-page-body-first-elem-input-btn {
    /* background-color: white;
    border-color: red;
    color: red; */
    background-color: red;
    color: white;
    font-size: 24px;
    font-weight: 600;
    border-radius: 30px;
    height: 80%;
    width: 70%;
    margin-right: 10px;
    cursor: unset;
}

 .main-page-body-first-elem-input-btn:focus-visible {
    outline: none;
    border: none;
}

.main-page-body-first-elem-input-btn-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.arrow-icon {
    width: 60px;
    height: 25px;
}

.main-page-body-first-elem-button {
    height: 45px;
    display: flex;
    background-color: #FFFFFF;
    color: #F80E0E;
    font-size: 20px;
    width: 200px;
    border-radius: 15px;
    margin-top: 20px;
    border-width: 2px;
    border-color: red;
}

.main-page-body-first-elem-button:hover {
    color: white;
    background-color: red;
}

.main-page-body-second-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.main-page-body-second-container-content {
    width: 90%;
    height: 90%;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
}

.main-page-body-second-container-block {
    display: inline-block;
    align-self: center;
    justify-self: center;
    padding: 10px;
}

.main-page-body-second-container-block-text-1 {
    font-size: 40px;
    font-weight: 500;
    width: 100%;
}

.main-page-body-second-container-block-text-2 {
    font-size: 18px;
    color: #434343;
}

.main-page-body-third-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.main-page-body-third-container-content {
    width: 90%;
    height: 90%;
    justify-self: center;
}

.main-page-body-fourth-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.main-page-body-fourth-container-content {
    width: 90%;
    height: 90%;
    flex-direction: column;
    display: flex;
}

.main-page-body-third-elem-text-1 {
    font-size: 30px;
    font-weight: 550;
    width: 100%;
    text-align: center;
}

.main-page-body-third-elem-text-2 {
    margin: 30px 0 30px 0;
    font-size: 18px;
    color: #434343;
    text-align: center;
}

.main-page-body-fifth-container {
    width: 100%;
    height: 100%;
    background-color: #F7F7F7A6;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.main-page-body-fifth-container-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.main-page-body-fifth-container-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    height: 90%;
}

.main-page-body-fifth-container-left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    gap: 20px;
}

.main-page-body-fifth-container-left-side-first {
    font-size: 28px;
    font-weight: 500;
}

.main-page-body-fifth-container-left-side-second {
    color: #434343;
    font-size: 20px;
    margin-top: 10px;
    margin-right: 50px;
}

.main-page-body-fifth-container-left-side-third{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    justify-content: start;
}

.main-page-body-fifth-container-left-side-third-elem {
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: 400px;
}

.main-page-body-fifth-container-left-side-third-elem1 {
    font-size: 22px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 100%;
}

.main-page-body-fifth-container-left-side-third-elem2 {
    font-size: 18px;
    color: #434343;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-page-body-fifth-container-right-side {
    display: flex;
    align-items: center;
    justify-content: end;
}

.fifth-container-img { 
    height: 100%;
    width: 100%;
}

.main-page-body-sixth-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.main-page-body-sixth-container-content {
    height: 90%;
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 60px;
}

.main-page-body-sixth-container-head {
    font-size: 36px;
    font-weight: 500;
    width: 650px;
    text-align: center;
    margin: 30px 0 30px 0;
}

/* .main-page-office-footer {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
} */

.main-page-body-seventh-container-body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 60px 0 60px 40px;
}

.main-page-body-seventh-container {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.main-page-body-seventh-container-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 90%;
    height: 90%;
    background-image: url('../../../../public/static/main_page_office2.svg');
    background-position: start;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-page-body-seventh-container-body-head {
    background-color: #C3C3C366;
    width: 680px;
    height: 15%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-size: 42px;
    font-weight: 600;
    color: white;
    padding-left: 10px;
}

.main-page-body-seventh-container-body-head-text {
    color: white;
    font-size: 24px;
    font-weight: 500;
}

.main-page-body-seventh-container-input-container {
    flex-direction: column;
    display: flex;
    gap: 10px;
    margin: 40px 0 0 0;
    width: 70%;
    height: 60%;
}

.main-page-body-seventh-container-input {
    border-radius: 30px;
    width: 100%;
    height: 80%;
    font-size: 20px;
    padding: 20px;
    outline: none;
    border: none;
}

.main-page-body-seventh-container-input::placeholder {
    opacity: 0.5;
    font-size: 22px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
}

.main-page-body-seventh-container-button {
    background-color: red;
    width: 25%;
    height: 50px;
    color: white;
    margin-top: 20px;
    border-radius: 20px;
    font-size: 24px;
    font-weight: 400;
}