.extended-menu-container {
    width: 100%;
    overflow-y: auto;
    height:max-content;
}

.extended-menu-content {
    width: 95%;
    margin-left: 1%;
    margin-top: 1%;
}

.extended-menu-card {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    border-radius: 0.5rem;
    /* padding: 1rem 0; */
    cursor: pointer;
    margin-bottom: 1rem;
    gap: 1rem;

    box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
    -webkit-box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
    -moz-box-shadow: 0 0 0.125rem 0 color-mix(in srgb, #223548 16%,transparent),0 0.5rem 0.5rem 0 color-mix(in srgb, #223548 16%,transparent);
}

.extended-menu-card-icon {
    background-color: #ccebfc;
    padding: 1rem;
    color: inherit;
    border-radius: 50%;
    margin-left: 1rem;
}

.extended-menu-card-icon:hover {
    border: none !important;
    outline: none !important;
}

.extended-menu-card-body {
    text-align: left;
    line-height: 0.8;
}

.extended-menu-card-body > h3 {
    font-size: 1.2rem;
    color: inherit;
}

.extended-menu-card-body > p {
    font-size: 0.8rem;
    color: #333;
}