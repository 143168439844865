.UserRoadMapContainerElem-main {
    display: flex;
    flex-direction: row;
    width: 32%;
    height: 300px;
    padding: 10px 10px 10px 0;
    gap: 10px;
    box-shadow: inset 50px 0 25px -15px rgba(0, 0, 0, 0.05)
}

.UserRoadMapContainerElem-logo {
    justify-content: start;
    height: 100%;
    width: auto;
}

.UserRoadMapContainerElem-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.UserRoadMapContainerElem-body-title {
    font-size: 24px;
    font-weight: 600;
    color: #2D2D2D;
}

.UserRoadMapContainerElem-body-text {
    font-size: 16px;
    color: #434343;
}

.UserRoadMapContainerElem-body-button {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.UserRoadMapContainerElem-button {
    background-color: white;
    color: red;
    border-width: 3px;
    border-color: red;
    border-radius: 25px;
    width: inherit;
    height: 80%;
}

.UserRoadMapContainerElem-button:hover {
    background-color: red;
    color: white;
}