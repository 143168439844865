.catalog-offer-product-card{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.catalog-offer-product-card-info{
    width: 400px;
    padding: 10px;
    border-radius: 10px;
}

.catalog-offer-product-card-info > div{
    padding: 10px;
}

.catalog-offer-product-component-page-section{
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;
}