.main-page {
  background: transparent;
  display: flex;
}

.main_page__content {
  width: 100%;
  position: relative;
  background: transparent;
  min-height: calc(100%);
}
