.order-project-content {
    position: relative;
    width: 100%;
    height: 600px;
    /* padding: 10px; */
}

.order-projects-view-table {
    width: 100%;
    height: 100%;
    th div {
        white-space: nowrap;
        flex-wrap: nowrap;
    }

    td, span {
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    /*th:nth-child(n + 3) {*/
    /*    min-width: auto;*/
    /*    width: auto;*/
    /*    color: red;*/
    /*}*/

    /*td:nth-child(n + 3) {*/
    /*    min-width: auto;*/
    /*    width: auto;*/
    /*}*/
    td:nth-child(n+3) button {
        width: inherit;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }
    td:nth-child(6) button , td:nth-child(7) button{
        width: inherit;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    td:nth-child(8) button, td:nth-child(13) button {
       justify-content: flex-end;
       align-items: flex-end;
    }
}

.order-project-table-fixed {
    gap: 10px;
    display: flex;
    align-items: center;
    color: #4E4E4E
}

.order-project-table-step-input {
    width: 150px;
}