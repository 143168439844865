.UserWizard__main__container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.UserWizardAddressCreateForm-modal-close-btn {
    width: var(--header-height);
    height: var(--header-height);
    border-radius: 0 var(--window-border-radius) 0 0;
    background: transparent;
    position: relative;
    display: block;
    z-index: 200;
    text-indent: -9999px;
    cursor: pointer;
    padding: 2px;
    transition: all 0.3s ease-out;
  }
  
  .UserWizardAddressCreateForm-modal-close-btn:before,
  .UserWizardAddressCreateForm-modal-close-btn:after {
    content: '';
    width: 80%;
    height: 2px;
    background: #7c7c7c;
    position: absolute;
    top: 45%;
    left: 10%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
  }
  
  .UserWizardAddressCreateForm-modal-close-btn:after {
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
  }

.UserWizardProjectCreateForm-modal-main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UserWizardProjectCreateForm-modal-container {
  width: auto;
  height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
}

.UserWizardProjectCreateForm-modal-content-header {
  width: 100%;
  height: 25%;
  display: flex;
  align-items: start;
  justify-content: end;
}

.UserWizardProjectCreateForm-modal-content {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UserWizardProjectCreateForm-modal-content-h1 {
  font-size: xx-large;
  margin: 5px;
}

.UserWizardProjectCreateForm-modal-content-h3 {
  font-size: larger;
  margin: 5px;
  margin-bottom: 10%;
}