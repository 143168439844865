.window-menu-wrapper{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    background-color: transparent;
    opacity: 0.6;
    z-index: 70;
}

.window-menu{
    background-color: rgb(101, 100, 100);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 220px;
    height: 90px;
    border-radius: 7px;
    z-index: 100;
    position: absolute;
    top: 10px;
    left: calc(50vw - 125px);
    opacity: 0.7;
    padding: 5px;
    font-size: small;
}

/* group */

.window-menu-group{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95px;
    height: 73px;
}

.group-rectangle{
    align-items: center;
}

.group-square{
    flex-wrap: wrap;
}

.window-menu-group div{
    position: relative;
    background-color: gray;
    border: 1px solid lightgray;
}

.group-cancel{
    justify-content: center;
    width: 230px;
    height: 25px;
}


/* component */

.window-menu-component{
    width: 45px;
}

.component-rectangle{
    height: 70px;
}

.rectangle-left{
    border-radius: 7px 0px 0px 7px;
}

.rectangle-right{
    border-radius: 0px 7px 7px 0px;
}

.component-square{
    height: 31px;
}

.square-top-left{
    border-radius: 7px 0px 0px 0px;
}

.square-top-right{
    border-radius: 0px 7px 0px 0px;
}

.square-bottom-left{
    border-radius: 0px 0px 0px 7px;
}

.square-bottom-right{
    border-radius: 0px 0px 7px 0px;
}

.window-menu-component.component-active{
    border: 3px solid white;
}

.group-active{
    color: white;
}