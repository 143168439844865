.CatalogSideView-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.CatalogSideView-content {
    width: 98%;
    height: 100%;
}

.CatalogSideView-table {
    height: 100%;
    width: 100%;
}

.CatalogSideView-paginator {
    position: absolute;
    height: 1%;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
}

.MuiTableRow-root:hover {
    background-color: #e1f4ff;
}

.product-image-galery {
    height: 100%;
}