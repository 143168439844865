.calculation-content {
  height: 100%;
}

.title {
  font-family: "Helvetica", sans-serif;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.aggregator-switch {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.aggregator-btn {
  transition: 0.3s ease;
  text-decoration: none;
  outline: none;
  padding: 1rem 2rem;
  border: 2px solid #d21531;
  border-radius: 1rem;
  background-color: #fff;
  font-family: "Helvetica", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #d21531;
}

.aggregator-btn:hover {
  color: #fff;
  background-color: #d21531;
}

.choosed-aggr-btn {
  text-decoration: none;
  outline: none;
  padding: 1rem 2rem;
  border-radius: 1rem;
  background-color: #d21531;
  border: 2px solid #d21531;
  font-family: "Helvetica", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
}

.parameters-input-content {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.locality-input {
  width: 100%;
  border: 2px solid #d21531;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.5rem;
}

.locality-input-container {
  position: relative;
  width: 100%;
}

.locality-dropdown {
  position: absolute;
  display: none;
  border: none;
}

.locality-dropdown-open {
  width: 100%;
  position: absolute;
  display: block;
  background-color: #fff;
  border: 2px solid #d21531;
  border-radius: 1rem;
  padding: 0.5rem;
  background-color: #dddddd;
  z-index: 2;
}

.dropdown-geoguid-element {
  text-align: center;
  border: 2px solid #d21531;
  border-radius: 1rem;
  color: #d21531;
  font-size: 1rem;
  padding: 0.3rem;
  background-color: #fff;
}

.dropdown-geoguid-element:hover {
  cursor: pointer;
  color: #fff;
  background-color: #d21531;
}

.calculate-btn {
  transition: 0.3s ease;
  text-decoration: none;
  outline: none;
  padding: 1rem;
  border: 2px solid #d21531;
  border-radius: 1rem;
  background-color: #fff;
  font-family: "Helvetica", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #d21531;
  margin-bottom: 1rem;
}

.calculate-btn:hover {
  color: #fff;
  background-color: #d21531;
}

.container {
  position: relative;
}

.button {
  padding: 0;
  width: 100px;
  border: 0;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  outline: 0;
  font-size: 20px;
}

.dropdown-btn {
  width: 100%;
  transition: 0.3s ease;
  text-decoration: none;
  padding: 0.5rem;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: #fff;
  font-family: "Helvetica", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #d21531;
  border: 2px solid #d21531;
}

.open {
  border-radius: 1rem 1rem 0 0;
  opacity: 1;
}

.dropdown-btn:hover {
  color: #fff;
  background-color: #d21531;
}

.dropdown-container {
  position: relative;
  border-radius: 1rem;
}

.dropdown-content {
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0 0 1rem 1rem;
  background-color: #dddddd;
  z-index: 2;
}

.dropdown-content-open {
  opacity: 1;
}

.dropdown-input {
  margin-bottom: 0.5rem;
  border: 2px solid #d21531;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.calculation-results-content {
  z-index: -1;
}

.calculation-results-title {
  display: flex;
  text-align: center;
  gap: 2rem;
}

.result-title-element {
  width: 100%;
  border-right: 2px solid #d21531;
}
.calculation-result {
  display: flex;
  text-align: center;
  gap: 2rem;
}

.calculation-result-element {
  width: 100%;
  border: 2px solid #d21531;
  border-radius: 1rem;
}
