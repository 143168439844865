.paginator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 1rem auto;
}

.paginator-box {
    width: 100%;
    gap: 1rem;
}

.active {
    background-color: #0070f2;
    color: white;
    z-index: 0;
}

.paginator-elem {
    margin: 0 2px;
}

.none {
    display: none;
}

.page-input {
    width: auto;
}