.tracking-timeline-item-success {
  --sapContent_NonInteractiveIconColor: #c3ee37;
  --sapContent_ForegroundBorderColor: #c3ee37;
}

.tracking-timeline-item-working {
  --sapContent_NonInteractiveIconColor: #6fc7f7;
  --sapContent_ForegroundBorderColor: #6fc7f7;
}

.tracking-timeline-item-canceled {
  --sapContent_NonInteractiveIconColor: #fa5959;
  --sapContent_ForegroundBorderColor: #fa5959;
}

.OrderItemAddToOrderProjectView-form-modal {
  left: 2%;
  transition: 0.3 all ease;
  width: 96%;
}
.isDelivered {
  background-color: #ebf5cb;
  color: #256f3a;
  opacity: 1;
}
.isDelivered:disabled {
  background-color: #ebf5cb;
  color: #256f3a;
  opacity: 1;
  cursor: not-allowed;
}