.comment-card-view-container {
    width: 100%;
    height: 100%;
}

.comment-card-view-content, .comment-card-view-content-reply-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    padding: 10px;
    background-color: #f1f1f1;
    transition: background-color 0.2s ease;
}

.comment-card-view-body, .comment-card-view-body-reply {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;
}

.reply-button {
    position: absolute;
    top: 10px;
    right: 60px;
    padding: 5px 10px;
    color: #000000;
    border-radius: 15px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.comment-card-view-content:hover .reply-button {
    opacity: 1;
}

.comment-card-view-content-user-icon {
    height: 40px;
    width: 40px;
    border-radius: 50px;
}

.comment-card-view-body-username {
    font-size: 20px;
    font-weight: bold;
}

.comment-card-view-content-menu-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
}

.comment-card-view-body-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.comment-card-view-body-image, .comment-card-view-body-image-reply {
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 500px;
    max-height: 500px;
    width: auto;
    height: auto;
    display: block;
    object-fit: cover;
    cursor: pointer;
}

.comment-card-view-content-date {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 10px;
    padding: 5px 10px;
    opacity: 0.5;
    gap: 10px;
}

.comment-card-view-content-reply-content {
    background-color: #e1f4ff;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    border-radius: 15px;
    position: relative;
    border: 1px solid #7bcfff;
    margin: 20px 60px 0 0;
}

.comment-card-view-content-reply-content::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 100%;
    border-radius: 20px;
    border: 4px solid #0070f2;
    transform: translateY(-50%);
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.comment-card-view-content-reply-content:hover {
    background-color: #c1e0f6;
}

.comment-card-view-body-reply {
    max-height: 150px;
}

.comment-card-view-body-text, .comment-card-view-body-text-reply {
    font-size: 20px;
    font-weight: medium;
}

.comment-card-view-body-text-reply {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.comment-card-view-body-image-reply {
    max-width: 100px;
    max-height: 100px;
}

.comment-card-view-body-reply-decline-btn {
    position: absolute;
    top: 5px;
    right: 5px;
}

.highlight {
    animation: highlight-animation 2s ease;
}

.file-view {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    cursor: pointer;
    padding: 5px;
    border-radius: 10px;
}

.file-view:hover {
    background-color: #e0dfdf;
}

@keyframes highlight-animation {
    from {
        background-color: #7bcfff; /* Цвет подсветки */
}
    to {
        background-color: transparent;
    }
}