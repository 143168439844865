:root {
    --UserCompanyView-header-height: 50px;
    --companies-container--margin: 10px;
}

.UserCompanyView-container {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.UserCompanyView-header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 0 1rem;
    height: var(--UserCompanyView-header-height);
    width: 100%;
}

.companies-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding: 1rem;
    margin: 0 var(--companies-container--margin);

    position: absolute;
    top: var(--UserCompanyView-header-height);
    left: 0;
    z-index: 1;
    max-height: calc(100% - var(--UserCompanyView-header-height) - var(--companies-container--margin));
    width: 100%;
    overflow-y: auto;
}
