.ProductSearcherWithWizardMain__main__conatainer {
    width: 100vw;
    height: 100% !important;
}

.ProductSearcherWithWizardMain__body {
    width: 80%;
    margin: 0 auto;
    height: calc(100vh - 70px);
    margin-top: 70px;

    /* display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 1rem; */
}

#ProductSearcherWithWizardMain-controller {
    position: fixed;
    bottom: 0;
    right: 100px;
    z-index: 1;

    padding: 5px 20px;
    border-radius: 10px 10px 0 0;
    border: none;
    cursor: pointer;
    background-color: #005FF9;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
}

#ProductSearcherWithWizardMain-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

#ProductSearcherWithWizardMain-layout {
    position: fixed;
    bottom: 10px;
    right: 100px;
    z-index: 3;
    width: 40%;
    height: 98%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 10px;
    background-color: rgb(34, 86, 232);;
    border-radius: 10px;
}

.ProductSearcherWithWizardMain-layout-close-sb-btn {
    width: var(--header-height);
    height: var(--header-height);
    border-radius: 0 var(--window-border-radius) 0 0;
    background: transparent;
    position: relative;
    display: block;
    z-index: 200;
    text-indent: -9999px;
    cursor: pointer;
    padding: 2px;
    transition: all 0.5s ease-out;
}

.ProductSearcherWithWizardMain-layout-close-sb-btn:before,
.ProductSearcherWithWizardMain-layout-close-sb-btn:after {
    content: '';
    width: 80%;
    height: 4px;
    background: #fff;
    position: absolute;
    top: 45%;
    left: 10%;
    transform: rotate(45deg);
    transition: all 0.5s ease-out;
}

.ProductSearcherWithWizardMain-layout-close-sb-btn:after {
    transform: rotate(-45deg);
    transition: all 0.5s ease-out;
}


.ProductSearcherWithWizardMain-layout {
    transform: translate(0, 110%);
    transition: all 0.5s ease-out;
}

.ProductSearcherWithWizardMain-layout-open {
    transform: translate(0, 0);
    transition: transform 0.5s ease;
}

.ProductSearcherWithWizardMain-layout-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.ProductSearcherWithWizardMain-layout-header {
    display: flex;
    align-items: center;
    height: 6%;
    width: 95%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 2.5%;
    z-index: 1;
    color: #fff;
    justify-content: space-between;
}

.ProductSearcherWithWizardMain-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 94%;
    background-color: #fff;
    border-radius: 10px;

    overflow-y: auto;
}

.ProductSearcherWithWizardMain-container {
    height: 100%;
    width: 94%;
    margin: 10px auto;
}

.ProductSearcherWithWizardMain__searcher__conatainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.main-logo-a-search-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 80px;
    margin-bottom: 50px;
}

.main-logo-search-page {
    width: 350px;
    height: 80px;
    margin-bottom: 50px;
}

.ProductSearcherWithWizardMain__search {
    width: 584px;
    margin-top: -50px;
    font-size: 1.5rem;
}

@media screen and (max-width: 1900px) {
    #ProductSearcherWithWizardMain__body {
        width: 80%;
    }
}

@media screen and (max-width: 1400px) {
    #ProductSearcherWithWizardMain__body {
        width: 90%;
    }
}

@media screen and (max-width: 1200px) {
    #ProductSearcherWithWizardMain__body {
        width: 95%;
    }
}

@media screen and (max-width: 1000px) {
    #ProductSearcherWithWizardMain-layout {
        right: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }

    .ProductSearcherWithWizardMain-layout-header {
        height: 6%;
    }

    .ProductSearcherWithWizardMain-content {
        top: 6%;
        height: 94%;
    }
}