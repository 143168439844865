.AdminCatalogProductsTable {
  height: 100%;
}

.NewProductCreateForm {
  max-height: 80%;
  width: 45%;
}

.ProductSortingForm {
  left: 15%;
  right: 15%;
  width: auto;
}

.brand-manufacture-create-form-modal{
  width: 30%;
  height: 40%;
}