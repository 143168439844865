.IncomingRequestPvzChooseModal {
    top: 10vh;
    height: 40vh;
}

@media screen and (max-width: 1600px) {
    .IncomingRequestPvzChooseModal {
        top: 10vh;
        height: 20vh;
    }
}

@media screen and (max-width: 768px) {
    .IncomingRequestPvzChooseModal {
        top: 10vh;
        height: 15vh;
    }
}