.catalog-main-page-container {
    border-color: #DADADA;
    border-radius: 40px;
    box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
}

.catalog-main-page-container-header {
    padding: 70px;
    font-size: 50px;
    font-weight: 500;
}

.catalog-main-page-container-header-button {
    margin-left: 30px;
    color: #F80E0E;
    border-radius: 40px;
    border-color: #F80E0E;
    font-size: 24px;
    font-weight: 600;
    width: inherit;
    height: 50px;
}

.catalog-main-page-container-header-button:hover {
    background-color: red;
    color: white;
}

.catalog-main-page-container-cards {
    padding: 40px 10px 40px 10px;
    gap: 20px;
}